// HowQrCodesWorkText.js
import React from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// ..
AOS.init();
const HowQrWorkText = () => {
    return (
        <div data-aos="fade-right" className="md:md:w-11/12">
            <h2 className="text-4xl font-bold mb-4">How QR Codes Work?</h2>
            <p className="text-lg leading-relaxed">
                QR codes, short for Quick Response codes, work by encoding information into a two-dimensional pattern that can be scanned by mobile devices or QR scanners. When scanned, they instantly reveal data, which may include URLs, contact info, or text, making them perfect for modern marketing and digital sharing.
            </p>
        </div>
    );
};

export default HowQrWorkText;
