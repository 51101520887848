// WhoWeAreText.js
import React from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

// ..
AOS.init();
const WhoWeAreText = () => {
    return (
        <div data-aos="fade-left" className="md:w-11/12">
            <h2 className="text-4xl font-bold mb-4">Why Choose QR Blitz?</h2>
            <p className="text-lg leading-relaxed">
                Welcome to QR Blitz, your go-to solution for generating high-quality QR codes in seconds. With our fast, intuitive tool, you can customize your QR codes effortlessly, ensuring they are both functional and beautifully designed. Whether you need codes for business, personal use, or marketing, QR Blitz is here to deliver 100% working results every time.
            </p>
        </div>
    );
};

export default WhoWeAreText;
