// StepsToCreateQRText.js
import React from 'react';

import AOS from 'aos';
//import 'aos/dist/aos.css'; // You can also use <link> for styles

// ..
AOS.init();
const StepsToCreateQRText = () => {
    return (
        <div data-aos="fade-right" className="w-full p-2 md:p-0 md:w-11/12">
            <h2 className="text-4xl font-bold mb-4">Steps to Create a QR Code</h2>
            <p className="text-lg leading-relaxed">
                Creating a QR code is simple with QR Blitz. First, enter the content you want to encode, like a URL or contact info. Select the size you want. Finally, click ‘Download PNG’ or ‘Download SVG’ and your QR code will be downloaded in high quality. For printing we recommend SVG format.
            </p>
        </div>
    );
};

export default StepsToCreateQRText;
