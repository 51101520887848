import React from 'react';

const QRCodeSize = ({ selectedSize, setSelectedSize }) => {
    const sizes = [150, 200, 250, 300]; // Predefined sizes

    return (
        <div className="mt-4 flex items-center space-x-4">
            <label className="text-gray-700 font-medium">Size:</label>
            <select
                value={selectedSize}
                onChange={(e) => setSelectedSize(parseInt(e.target.value))}
                className="p-2 border rounded"
            >
                {sizes.map((size) => (
                    <option key={size} value={size}>{size} px</option>
                ))}
            </select>
        </div>
    );
};

export default QRCodeSize;