import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios'; // Add axios import
import MainLayout from './components/MainLayout';
import QRCodeForm from './components/QRCodeForm';
import QRCodeDisplay from './components/QRCodeDisplay';
import DownloadButtons from './components/DownloadButtons';
import UserEngagementText from './components/UserEngagementText';
import QRCodeSize from './components/QRCodeSize';
import UserEngagementTextMobile from './components/UserEngagementTextMobile';
import WhoWeAreText from './components/WhoWeAreText';
import WhoWeAreImage from './components/WhoWeAreImage';
import HowQrWorkImage from './components/HowQrWorkImage';
import HowQrWorkText from './components/HowQrWorkText';
import UsedCasesText from './components/UsedCasesText';
import UsedCasesImage from './components/UsedCasesImage';
import StepsToCreateQRImage from './components/StepsToCreateQRImage';
import StepsToCreateQRText from './components/StepsToCreateQRText';
import BlitzBenefitImage from './components/BlitzBenefitImage';
import BlitzBenefitText from './components/BlitzBenefitText';
import FAQSection from './components/FAQSection';

function App() {
  const [qrData, setQrData] = useState(null);
  const [fgColor, setFgColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#ffffff');
  const svgRef = useRef(null); // Ref for hidden SVG element
  const [selectedSize, setSelectedSize] = useState(200); // Default size
  const [userIP, setUserIP] = useState(''); // New state to store IP

  const base_endpoint = "https://backend.qrblitz.com/"

  // Fetch the user's IP address using an external API
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setUserIP(response.data.ip); // Set the user's IP
        logVisitor(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchIP();

  }, []); // Runs only once when the component mounts

  const handleGenerate = (inputValue, foregroundColor, backgroundColor) => {
    setQrData(inputValue);
    setFgColor(foregroundColor);
    setBgColor(backgroundColor);
  };

  const logUserActivity = (format) => {
    const activityData = {
      ip: userIP, // Use the fetched IP address
      codeText: qrData,
      codeSize: selectedSize,
      downloadFormat: format,
    };
    console.log('Calling from fetch');
    console.log('Logging user activity:', activityData);
    fetch(base_endpoint + 'log-activity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activityData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Activity logged:', data);
      })
      .catch((error) => {
        console.error('Error logging activity:', error);
      });
  };


  const logVisitor = (ip) => {
    const visitorData = {
      ip: ip, // Use the fetched IP address
      agentText: navigator.userAgent,
    };
    console.log('Calling from fetch');
    console.log('Logging visitor:', visitorData);
    fetch(base_endpoint + 'log-visitor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(visitorData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Visitor logged:', data);
      })
      .catch((error) => {
        console.error('Error logging Visitor:', error);
      });
  };


  const handleDownloadPNG = () => {
    const canvas = document.querySelector('canvas');
    if (!canvas) return;

    const padding = 16; // This represents padding (p-4 equivalent)
    const originalSize = canvas.width; // Assuming the QR code is a square (width === height)
    const newSize = originalSize + 2 * padding;

    // Create a new canvas element with padding
    const paddedCanvas = document.createElement('canvas');
    paddedCanvas.width = newSize;
    paddedCanvas.height = newSize;

    const context = paddedCanvas.getContext('2d');

    // Fill the background with white (optional, if you want a background)
    context.fillStyle = '#ffffff'; // Background color
    context.fillRect(0, 0, newSize, newSize);

    // Draw the original QR code image onto the padded canvas
    context.drawImage(canvas, padding, padding, originalSize, originalSize);

    // Create the download link
    const link = document.createElement('a');
    link.href = paddedCanvas.toDataURL('image/png');
    link.download = 'qrcode_with_padding.png';
    link.click();
    logUserActivity('PNG');
  };

  return (
    <MainLayout>
      <div className="grid grid-cols-1 md:grid-cols-8 gap-2 p-1 px-0" style={{ minHeight: '68vh' }}>
        {/* Left Column (Input Area) - Takes full width on small screens, 5/8 on larger screens */}
        <div className="col-span-1 md:col-span-5 bg-white p-6 shadow-md rounded">
          <QRCodeForm onGenerate={handleGenerate} />
          <p className="text-center text-gray-600 mb-4">Your QR code will be generated automatically</p>
          <UserEngagementText />
        </div>

        {/* Right Column (QR Code Preview) - Takes full width on small screens, 3/8 on larger screens */}
        <div className="col-span-1 md:col-span-3 bg-gray-50 flex flex-col items-center shadow-md rounded pb-10 md:pb-0">
          <QRCodeDisplay data={qrData} fgColor={fgColor} bgColor={bgColor} isLoading={false} svgRef={svgRef} size={selectedSize} />
          <QRCodeSize selectedSize={selectedSize} setSelectedSize={setSelectedSize} />
          <DownloadButtons qrData={qrData} onDownloadPNG={handleDownloadPNG} svgRef={svgRef} onLogActivity={logUserActivity} />
        </div>

        {/* User Engagement Text for Mobile */}
        <UserEngagementTextMobile />

      </div>
      <section id="whoweare" className="my-16 p-2 w-full grid grid-cols-1 bg-white md:p-5">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Column - Text */}
          <div className='w-full p-2 bg-slate-300 md:p-10 md:w-9/12'>
            <WhoWeAreText />
          </div>
          {/* Right Column - Image */}
          <WhoWeAreImage />
        </div>
      </section>

      <section id="howqrworks" className="my-16 p-2 w-full grid grid-cols-1 bg-white md:p-5">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Column - Image */}
          <HowQrWorkImage />
          {/* Right Column - Text */}
          <div className='w-full p-2 bg-slate-300 md:p-10 md:w-9/12'>
            <HowQrWorkText />
          </div>
        </div>
      </section>

      <section id="usecases" className="my-16 p-2 w-full grid grid-cols-1 bg-white md:p-5">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Column - Text */}
          <div className='w-full p-2 bg-slate-300 md:p-10 md:w-9/12'>
            <UsedCasesText />
          </div>
          {/* Right Column - Image */}
          <UsedCasesImage />
        </div>
      </section>

      <section id="stepstocreate" className="my-16 p-2 w-full grid grid-cols-1 bg-white md:p-5">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Column - Image */}
          <StepsToCreateQRImage />
          {/* Right Column - Text */}
          <div className='w-full p-2 bg-slate-300 md:p-10 md:w-9/12'>
            <StepsToCreateQRText />
          </div>
        </div>
      </section>

      <section id="usecases" className="my-16 p-2 w-full grid grid-cols-1 bg-white md:p-5">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Column - Text */}
          <div className='w-full p-2 bg-slate-300 md:p-10 md:w-9/12'>
            <UsedCasesText />
          </div>
          {/* Right Column - Image */}
          <UsedCasesImage />
        </div>
      </section>

      <section id="qrblitzbenefits" className="my-16 p-2 w-full grid grid-cols-1 bg-white md:p-5">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Column - Image */}
          <BlitzBenefitImage />
          {/* Right Column - Text */}
          <div className='w-full p-2 bg-slate-300 md:p-10 md:w-9/12'>
            <BlitzBenefitText />
          </div>
        </div>
      </section>
      <section id="blitzFaq" className="my-16 p-2 w-full grid grid-cols-1 bg-white md:p-5">
        {/* Blitz FAQ */}
        <FAQSection />
      </section>
    </MainLayout>
  );
}

export default App;
