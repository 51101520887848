import React from 'react';

const FooterLayout = () => {
    return (
        <footer className="bg-gray-800 py-4 text-center text-white">
            <p>© 2024 QRBlitz.com</p>
            <p className='mx-10'>Developed By S4Strategic under guidence of Ms. Chat GPT.</p>
        </footer>
    );
};

export default FooterLayout;
