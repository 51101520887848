// BlitzBenefitText.js
import React from 'react';

import AOS from 'aos';
//import 'aos/dist/aos.css'; // You can also use <link> for styles

// ..
AOS.init();
const BlitzBenefitText = () => {
    return (
        <div data-aos="fade-right" className="w-full p-2 md:p-0 md:w-11/12">
            <h2 className="text-4xl font-bold mb-4">Benefits of Using QR Blitz</h2>
            <p className="text-lg leading-relaxed">
                With QR Blitz, enjoy ease, speed, and customization for your QR code creation. Our platform offers tools to personalize QR codes, download them instantly, and ensure compatibility across all devices, making QR Blitz perfect for digital engagement.
            </p>
        </div>
    );
};

export default BlitzBenefitText;
