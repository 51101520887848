// StepsToCreateQRImage.js
import React from 'react';

import AOS from 'aos';
//import 'aos/dist/aos.css'; // You can also use <link> for styles

import toCreateQR from '../assets/images/qrcode-placeholder.png'
const StepsToCreateQRImage = () => {
    return (
        <div data-aos="flip-left" className="md:w-2/5">
            <img
                src={toCreateQR}
                alt="QR Blitz in action"
                className="rounded-lg shadow-lg"
            />
        </div>
    );
};

export default StepsToCreateQRImage;
