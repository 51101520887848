import React from 'react';

const UserEngagementText = () => {
    return (
        <div className="mt-6 text-gray-700 flex-grow flex items-end self-end h-3/5 hidden md:flex">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 w-full">
                <div className="text-center bg-gray-50 p-5 flex flex-col justify-center">
                    <h3 className="font-semibold text-lg">Why use QR codes?</h3>
                    <p className="text-sm">
                        QR codes are a fast and convenient way to share information such as URLs, contact details, or promotions.
                        They can be scanned by any smartphone, making them accessible to everyone.
                    </p>
                </div>
                <div className="text-center bg-gray-50 p-5 flex flex-col justify-center">
                    <h3 className="font-semibold text-lg">How to use your QR code?</h3>
                    <p className="text-sm">
                        Once generated, you can print or display the QR code on your business cards, flyers, or websites.
                        People can scan it to instantly access the content you've linked.<br />
                        QR Blitz | Free Online QR Code Generator.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UserEngagementText;

