import React from 'react';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import placeholderQRCode from '../assets/images/qrcode-placeholder.png';

const QRCodeDisplay = ({ data, fgColor, bgColor, isLoading, svgRef, size }) => {
    return (
        <div className="flex items-start justify-center p-4 mt-4 bg-white rounded shadow-md">
            {isLoading ? (
                <div className="spinner"></div> // Spinner for loading
            ) : data ? (
                <>
                    {/* Visible QR Code in Canvas for PNG */}
                    <QRCodeCanvas value={data} size={size} fgColor={fgColor} bgColor={bgColor} style={{ width: '200px', height: '200px' }} />
                    {/* Hidden SVG element for SVG download */}
                    <div style={{ display: 'none' }}>
                        <QRCodeSVG ref={svgRef} value={data} size={size} fgColor={fgColor} bgColor={bgColor} />
                    </div>
                </>
            ) : (
                <img src={placeholderQRCode} alt="Placeholder QR" className="opacity-50" style={{ width: '200px', height: '200px' }} />
            )}
        </div>
    );
};

export default QRCodeDisplay;
