import React from 'react';

const DownloadButtons = ({ qrData, onDownloadPNG, svgRef, onLogActivity }) => {
    const downloadSVG = () => {
        if (svgRef.current) {
            const svgElement = svgRef.current;

            // Clone the original SVG element to add padding
            const clonedSvgElement = svgElement.cloneNode(true);

            // Create a wrapping <svg> element with padding
            const padding = 16; // p-4 equivalent
            const svgSize = 200; // Size of the QR code (you can adjust as needed)
            const paddedSvgSize = svgSize + 2 * padding;

            // Add wrapping SVG with padding and background color
            const wrapperSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            wrapperSvg.setAttribute('width', paddedSvgSize);
            wrapperSvg.setAttribute('height', paddedSvgSize);
            wrapperSvg.setAttribute('viewBox', `0 0 ${paddedSvgSize} ${paddedSvgSize}`);

            // Optional: Add a background rectangle for the QR code (e.g., white)
            const backgroundRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            backgroundRect.setAttribute('width', paddedSvgSize);
            backgroundRect.setAttribute('height', paddedSvgSize);
            backgroundRect.setAttribute('fill', '#ffffff'); // Background color

            // Append the background and the cloned QR code with padding
            wrapperSvg.appendChild(backgroundRect);
            clonedSvgElement.setAttribute('x', padding);
            clonedSvgElement.setAttribute('y', padding);
            wrapperSvg.appendChild(clonedSvgElement);

            // Serialize and download the SVG
            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(wrapperSvg);
            const blob = new Blob([svgString], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);

            // Trigger download
            const link = document.createElement('a');
            link.href = url;
            link.download = 'qrcode_with_padding.svg';
            link.click();
            onLogActivity('SVG');
            // Clean up
            URL.revokeObjectURL(url);
        }
    };

    return (
        <div className="mt-4 mb-0 sm:mb-5 sm:pb-5-">
            <button
                className={`mr-4 px-4 py-2 rounded ${qrData ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                disabled={!qrData}
                onClick={onDownloadPNG}
            >
                Download PNG
            </button>
            <button
                className={`px-4 py-2 rounded ${qrData ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                disabled={!qrData}
                onClick={downloadSVG}
            >
                Download SVG
            </button>
        </div>
    );
};

export default DownloadButtons;
