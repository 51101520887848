import React from 'react';
import HeaderLayout from './HeaderLayout';
import FooterLayout from './FooterLayout';

const MainLayout = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            {/* Header Section */}
            <HeaderLayout />

            {/* Main Content Section */}
            <main className="flex-grow">
                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    {children}
                </div>
            </main>

            {/* Footer Section */}
            <FooterLayout />
        </div>
    );
};

export default MainLayout;
