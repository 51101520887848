// WhoWeAreImage.js
import React from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import whoweImage from '../assets/images/whowe-are.png'
const WhoWeAreImage = () => {
    return (
        <div data-aos="flip-left" className="md:w-2/5">
            <img
                src={whoweImage}
                alt="QR Blitz in action"
                className="rounded-lg shadow-lg"
            />
        </div>
    );
};

export default WhoWeAreImage;
