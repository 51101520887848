// FAQSection.js
import React, { useState } from 'react';

const FAQSection = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqItems = [
        { question: "What is a QR code?", answer: "A QR code, short for Quick Response code, is a two-dimensional barcode that stores information, which can be scanned for instant access." },
        { question: "How does QR Blitz work?", answer: "QR Blitz makes creating a QR code easy. Enter your content, customize it, and generate a unique QR code." },
        { question: "Is QR Blitz free to use?", answer: "Yes, QR Blitz is completely free, offering unlimited QR code creation for personal and business needs." },
        { question: "Can I customize the colors of my QR code?", answer: "Absolutely! QR Blitz allows for color customization, helping you align QR codes with your brand or personal style." },
        { question: "What types of QR codes can I create?", answer: "QR Blitz supports various QR code types, such as URLs, text, and contact information, catering to diverse use cases." },
        { question: "Do QR codes expire?", answer: "No, static QR codes don’t expire. They remain functional indefinitely unless the linked content is changed or removed." },
        { question: "What formats can I download my QR code in?", answer: "QR Blitz provides QR codes in PNG and SVG formats, ensuring high-quality images suitable for print and digital use." },
        { question: "How do I use a QR code for marketing?", answer: "QR codes can direct customers to websites, promotions, or social media pages, making them a powerful tool in digital marketing." },
        { question: "Can I track QR code scans?", answer: "Currently, QR Blitz focuses on generating codes, not tracking. For tracking, you may use a separate QR tracking service." },
        { question: "Is there a limit to QR code size?", answer: "QR Blitz allows for different sizes to suit various needs, with larger sizes often easier to scan." },
        { question: "How safe are QR codes?", answer: "QR codes are secure, but it’s best to scan codes from trusted sources. QR Blitz generates codes without collecting personal data." },
        { question: "Can I generate QR codes for personal use?", answer: "Yes, QR Blitz is available for personal and commercial use, allowing you to create codes for any occasion or need." },
        { question: "What are the most popular uses for QR codes?", answer: "Popular uses include directing users to websites, sharing contact info, and linking to event details or digital menus." },
        { question: "How do I scan a QR code?", answer: "Use a smartphone camera or QR scanning app to scan codes. Most phones recognize QR codes automatically." },
        { question: "Is it possible to edit a QR code?", answer: "Once created, static QR codes can’t be edited. You’ll need to generate a new one if changes are needed." },
        { question: "How do QR codes work without Wi-Fi?", answer: "QR codes contain data within the code itself, making them accessible even without internet, depending on the linked content." },
        { question: "Can I generate a QR code for a PDF?", answer: "Yes, by uploading the PDF to a link-sharing platform, then generating a QR code that links to the document’s URL." },
        { question: "How do I add a QR code to printed materials?", answer: "Simply download your QR code from QR Blitz and insert it into your design software for use on flyers, business cards, etc." },
        { question: "How does QR Blitz protect my data?", answer: "QR Blitz doesn’t store personal data from QR code generation. Codes are created without requiring data collection or tracking." }
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section id="faq" className="p-6 bg-gray-100">
            <h2 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions [FAQ]</h2>
            {faqItems.map((item, index) => (
                <div key={index} className="bg-white border border-gray-200 p-4 mb-4 rounded-lg shadow-sm">
                    <div
                        className="flex justify-between items-center cursor-pointer text-lg font-semibold"
                        onClick={() => toggleFAQ(index)}
                    >
                        {item.question}
                        <span>{openIndex === index ? '-' : '+'}</span>
                    </div>
                    {openIndex === index && <p className="mt-2 text-gray-600">{item.answer}</p>}
                </div>
            ))}
        </section>
    );
};

export default FAQSection;
