import React from 'react';
import logo2 from '../assets/images/qrblitz-logo-3.png';

const HeaderLayout = () => {
    return (
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                {/* Left: Logo and Title */}
                <div className="flex items-center space-x-4">
                    {/* Placeholder for Logo */}

                    {/*
                    <div className="text-2xl font-bold text-blue-500 bg-blue-400" style={{ borderRadius: 7, padding: 5 }} >✨</div>
                    */}
                    <div className="text-2xl font-bold text-blue-500" >
                        <img src={logo2} alt='QR Blitz Logo' style={{ height: 54, borderRadius: 12 }}></img>
                    </div>
                    <div>
                        <h1 className="text-3xl font-bold text-gray-900">QR Blitz</h1>
                        <p className="text-sm text-gray-500">Create your QR code for free</p>
                    </div>
                </div>

                {/* Right: Login and Sign Up */}
                <div className="flex items-center space-x-4" style={{ display: 'none' }}>
                    <a href="#" className="text-sm font-medium text-gray-700 hover:text-gray-900">Login</a>
                    <a href="#" className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded hover:bg-blue-600">Sign Up</a>
                </div>
            </div>
        </header>
    );
};

export default HeaderLayout;
