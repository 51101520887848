// UsedCasesText.js
import React from 'react';

import AOS from 'aos';
//import 'aos/dist/aos.css'; // You can also use <link> for styles

// ..
AOS.init();
const UsedCasesText = () => {
    return (
        <div data-aos="fade-right" className="md:w-11/12">
            <h2 className="text-4xl font-bold mb-4">Use Cases for QR Codes</h2>
            <p className="text-lg leading-relaxed">
                QR codes offer endless possibilities for sharing information instantly. Businesses use QR codes on products, posters, and websites to connect customers with promotions, menus, and more. With a quick scan, users access content, making QR codes a powerful tool in marketing and customer engagement.
            </p>
        </div>
    );
};

export default UsedCasesText;
